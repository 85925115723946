import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'

import { ResourcesItem } from '../components/ResourcesComponents/ResourcesCard'
import styled from 'styled-components'
import TrackVisibility from 'react-on-screen'
import PromotionBannerAnimation from '../components/PromotionBannerAnimation'
import Arrow from '../images/link-arrow.svg'
import { resourceLimits } from 'worker_threads'

interface Props {
  data: any
  pageContext: any
}

const resources = ({ data, pageContext }: Props) => {
  //Get Data
  const { wpPage, allWpPost } = data
  const renderedBlocks = LazyBlocksFactory(wpPage.lazy_data, wpPage.title)
  const posts = allWpPost.nodes.map((v) => v)

  //Extract categories, make radio buttons
  const categories = posts.map((post) => post.resources.category)

  const resourcePerCategory = posts.reduce((r, e) => {
    // get first letter of name of current element
    let categorySort = e.resources.category
    // if there is no property in accumulator with this letter create it
    if (!r[categorySort]) r[categorySort] = { categorySort, children: [e] }
    // if there is push current element to children array for that letter
    else r[categorySort].children.push(e)
    // return accumulator
    return r
  }, {})
  const articlesByCategory = Object.values(resourcePerCategory)
  //  console.log('resourcePerCategory')
  //  console.log(articlesByCategory)
  const [filter, setFilter] = useState('All')
  // $.each(categories, (i, el) => {
  //   if ($.inArray(el, categoriesList) === -1) categoriesList.push(el)
  // })

  const categoriesList = categories.filter(
    (item, i, ar) => ar.indexOf(item) === i
  )

  const radioOptions = categoriesList.map((category, i) => {
    return (
      <>
        <input
          type="radio"
          id={'rad-' + i}
          name="employees"
          value={filter}
          onClick={() => {
            setFilter(category)
          }}
        />
        <label htmlFor={'rad-' + i}>
          {category} ({articlesByCategory[i].children.length})
        </label>
      </>
    )
  })

  //Filter posts
  const [filteredPosts, setFilteredPosts] = useState(posts)

  useEffect(() => {
    if (filter === 'All') {
      setFilteredPosts(posts)
      setCurrentPage(1)
    } else {
      setFilteredPosts(posts.filter((v) => v.resources.category === filter))
      setCurrentPage(1)
    }
  }, [filter])

  //pagination

  const postsPerPage = 13

  const [totalNumberOfPages, setTotalNumberOfPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageButtons, setPageButtons] = useState([])
  const [viewingItems, setViewingItems] = useState([])

  useEffect(() => {
    let result = filteredPosts

    // filteredPosts.map((v) => {
    //   if (
    //     v.resources.isolatedForLandingPage === null ||
    //     v.resources.isolatedForLandingPage === false ||
    //     v.resources.isolatedForLandingPage === undefined
    //   ) {
    //     console.log(v)
    //   }
    //   if (filteredPosts.length >= currentPage * postsPerPage) {
    //     result = filteredPosts.slice(
    //       (currentPage - 1) * postsPerPage,
    //       currentPage * postsPerPage
    //     )
    //   }
    //   if (
    //     filteredPosts.length <= currentPage * postsPerPage &&
    //     filteredPosts.length >= (currentPage - 1) * postsPerPage
    //   ) {
    //     result = filteredPosts.slice(
    //       (currentPage - 1) * postsPerPage,
    //       filteredPosts.length
    //     )
    //   }
    // })

    let newResults = []

    result.forEach((v) => {
      if (
        v.resources.isolatedForLandingPage === null ||
        v.resources.isolatedForLandingPage === false ||
        v.resources.isolatedForLandingPage === undefined
      ) {
        newResults.push(v)
      }
    })

    // console.log(newResults)

    if (newResults.length >= currentPage * postsPerPage) {
      result = newResults.slice(
        (currentPage - 1) * postsPerPage,
        currentPage * postsPerPage
      )
    }
    if (
      newResults.length <= currentPage * postsPerPage &&
      newResults.length >= (currentPage - 1) * postsPerPage
    ) {
      result = newResults.slice(
        (currentPage - 1) * postsPerPage,
        newResults.length
      )
    }

    // console.log(newResults)

    setViewingItems(result)
    // console.log(posts)
  }, [currentPage, filteredPosts])
  useEffect(() => {
    if (filteredPosts.length >= postsPerPage) {
      setTotalNumberOfPages(Math.floor(filteredPosts.length / postsPerPage))
    } else {
      setTotalNumberOfPages(1)
    }
  }, [filteredPosts])

  useEffect(() => {
    if (totalNumberOfPages >= 5) {
      const items = []
      for (let i = 0; i < totalNumberOfPages; i++) {
        let isHidden = true
        //first one
        if (i === 0) {
          isHidden = false
        }
        //last one
        if (i === totalNumberOfPages - 1) {
          isHidden = false
        }
        //within 5 of current
        if (currentPage - 2 < i + 1 && i + 1 < currentPage + 2) {
          isHidden = false
        }

        items.push(
          <PageButton
            onClick={() => setCurrentPage(i + 1)}
            active={i + 1 === currentPage}
            // hidden={isHidden}
            className={isHidden ? 'pagination-dots' : ''}
          >
            {i + 1}
          </PageButton>
        )
      }
      setPageButtons(items)
    }
  }, [totalNumberOfPages, currentPage])

  useEffect(() => {
    // console.log(currentPage)
  }, [currentPage])

  //Breadcrumbs
  const breadcrumbData = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'Resources',
      url: '/resources/',
    },
  ]

  // const filterOptions = ["all"]
  return (
    <Layout>
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} color={'black'} />
      </MainGrid>

      {renderedBlocks.map((v) => v)}
      <MainGrid>
        <Title>Resources</Title>

        <RadioContainer>
          <input
            type="radio"
            id={'rad-all'}
            name="employees"
            value={filter}
            defaultChecked
            onClick={() => {
              setFilter('All')
            }}
          />
          <label htmlFor={'rad-all'}>All</label>
          {radioOptions.map((v) => v)}
        </RadioContainer>

        <hr />
        <GridContainer>
          {viewingItems.map((v, i) => (
            <ResourcesItem key={v.id} post={v} index={i} />
          ))}
        </GridContainer>
        {pageButtons.length > 1 ? (
          <PaginationRow>
            <ArrowImage
              src={Arrow}
              left={true}
              active={currentPage > 1}
              onClick={() =>
                currentPage > 1 ? setCurrentPage(currentPage - 1) : null
              }
            />

            {pageButtons.map((v) => v)}

            <ArrowImage
              src={Arrow}
              left={false}
              active={currentPage < totalNumberOfPages}
              onClick={() =>
                currentPage < totalNumberOfPages
                  ? setCurrentPage(currentPage + 1)
                  : null
              }
            />
          </PaginationRow>
        ) : null}
      </MainGrid>

      {/* <resourcesForm /> */}
      {/* <JobApplicationForm />
      <PartnerForm /> */}
    </Layout>
  )
}

interface PageButtonProps {
  active: boolean
  // hidden: booelan
}
const RadioContainer = styled.div`
  > input[type='radio'] + label {
    margin-right: 10px;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px !important;
    /* padding-left: 20px; */
    cursor: pointer;
    display: inline-block;
    color: #c70f55;
    border-radius: 5px;
    background-color: white;
    min-width: 101px;
    padding-top: 11px;
    padding-bottom: 11px;
    border: 1px solid #c70f55;
    text-align: center;
    /* min-height: 60px; */
    /* display: flex; */
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
  }

  > input[type='radio']:checked + label {
    color: white;
    background-color: #c70f55;
  }
`

const PageButton = styled.div<PageButtonProps>`
  font-weight: ${(props) => (props.active ? '600' : '400')};
  color: ${(props) => (props.active ? '#00A3C7' : '#1d1e22')};
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
`

interface ArrowImageProps {
  left: boolean
  active: boolean
}

const ArrowImage = styled.img<ArrowImageProps>`
  cursor: pointer;
  width: 48px;
  height: 15px;
  transform: ${(props) => (props.left ? 'scaleX(-1)' : 'scaleX(1)')};
  opacity: ${(props) => (props.active ? '1' : '0.3')};
`

const PaginationRow = styled.div`
  border-top: 1px solid #dadada;
  margin-top: 60px;
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
`
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 30px;
  row-gap: 30px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`

const Title = styled.h1`
  padding-top: 55px;
  padding-bottom: 55px;
`

export default resources

export const pageQuery = graphql`
  query resources {
    wpPage(title: { eq: "Resources" }) {
      id
      lazy_data
      title
    }
    allWpPost {
      nodes {
        id
        slug
        lazy_data
        date
        title
        resources {
          fieldGroupName
          category
          description
          isolatedForLandingPage
          featuredImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1080, toFormat: WEBP) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
